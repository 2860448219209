import request from "./request";

export function addConsultInfo(form) {
  return request.post("/api/consultInfo", form);
}
export function getNewsList(params) {
  return request.get("/api/news/list", { params });
}
export function getNewsDetails(id) {
  return request.get(`/api/news/${id}`);
}
